export const routes = [
    //天梯计划
    {
        path: '/ladder',
        name: 'ladder',
        meta:{title:'天梯计划'},
        component:  () => import('@/views/ladder/index.vue'),
    },
    {
        name: 'ladderStage',
        path: "/ladder/stage",
        meta:{title:'天梯计划',login:true},
        component: () => import('@/views/ladder/stage.vue'),
    },
    {
        name: 'ladderLane',
        path: "/ladder/lane",
        meta:{title:'快速通道',login:true},
        component: () => import('@/views/ladder/lane.vue'),
    },
    {
        name: 'ladderAnswer',
        path: "/ladder/answerProblem",
        meta:{title:'题目详情',login:true},
        component: () => import('@/views/ladder/answerProblem.vue'),
    },
]